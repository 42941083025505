;(function ($) {

    var geocoder;
    var infowindow;
        var map;
        function initMap() {
            var map = new google.maps.Map(document.getElementById('map'), {
                zoom: 9,
                center: { lat: 38.627, lng: -90.199 }
            });
            infowindow = new google.maps.InfoWindow({
                content: '',
                maxWidth: 300
            });
            geocoder = new google.maps.Geocoder();
            $('#public-art-container article').each(function( i ) {
                var address = $(this).find('.public-art-address').attr('data-address');
                var title = $(this).find('.public-art-address').attr('data-title');
                var link = $(this).find('.public-art-address').attr('data-link');
                var region = $(this).find('.public-art-address').attr('data-region');
                //console.log( i + ": " + address );
                codeAddress(geocoder, map, address, title, link, region);
            });
            
        }

        function codeAddress(geocoder, map, address, title, link, region) {
            //console.log({infowindow})
            geocoder.geocode({'address': address}, function(results, status) {
            if (status === 'OK') {
                map.setCenter(results[0].geometry.location);
                const contentString =
                    '<div id="content">' +
                    '<div style="text-align: center;" id="siteNotice">' +
                    "</div>" +
                    '<h5 style="text-align: center; font-size: 20px;" id="firstHeading" class="firstHeading">' + title + '</h5>' +
                    '<div style="text-align: center;" id="bodyContent">' +
                    '<p style="margin-top: 10px;">' + region + '</p>' +
                    '<p><a href="' + link + '">Learn More</a></p>' +
                    "</div>" +
                    "</div>";
                // const infowindow =/' new google.maps.InfoWindow({
                //     content: contentString,
                //     maxWidth: 200,
                // });
                //const infoWindow = new google.maps.InfoWindow;
                var marker = new google.maps.Marker({
                    map: map,
                    position: results[0].geometry.location
                });
                marker.addListener("click", () => {
                    console.log(infowindow)
                    //infowindow.close();
                    infowindow.setContent(contentString);
                    infowindow.open(map, marker);
                  });
            } else {
                //console.log('Geocode was not successful for the following reason: ' + status);
            }
            });
        }


    function getFilteredArt( pageToLoad = 1, last = false ) {
        var offset = $('#public-art-offset').val();
        var form = $('#filter-public-art').serializeArray();
        
        $.ajax({
            type : "POST",
            dataType : "json",
            url : "/wp-admin/admin-ajax.php",
            data : {
                action: "get_filtered_art",
                formData: form,
                offset: offset,
                page: pageToLoad,
                last: last
            },
            success: function(response) {
                console.log(response);
                if( $('#g-map').hasClass('selected') ) {
                    // fill in the results on the other views but don't show them yet
                    $('#public-art-container').hide().html(response.results);
                    $('.pagination-container').html(response.paging);
                    // put the new results on the map
                    refreshMap(response.results);
                } else {
                    $('#public-art-container').hide().html(response.results).fadeIn();
                    $('.pagination-container').html(response.paging);
                    refreshMap(response.results);
                }
                
            },
            error: function (error) {
                console.log(error);
              }
        });
    }

    function refreshMap(filteredArt) {
        console.log(filteredArt);
        initMap();
    }

	$(document).ready(function(){

        //grantee preselect filters
        var selectedDiscipline = $('.grantee-filters select#discipline').attr('data-filter');
        var selectedSlug = $('.grantee-filters select#grant_slug').attr('data-filter');
        if(selectedDiscipline) {
            $('.grantee-filters #discipline option').each(function(e) {
                if($(this).val() === selectedDiscipline) {
                    $(this).attr('selected', 'selected');
                }
            });
        }
        if(selectedSlug) {
            $('.grantee-filters #grant_slug option').each(function(e) {
                if($(this).val() === selectedSlug) {
                    $(this).attr('selected', 'selected');
                }
            });
        }

        if( $("body").hasClass('page-id-65') ) {
            // Construct URL object using current browser URL
            var url = new URL(document.location);

            // Get query parameters object
            var params = url.searchParams;
            var type = params.get("type");
            var discipline = params.get("discipline");

            // Set it as the dropdown value
            $("#cat_type").val(type);
            $("#cat_discipline").val(discipline);
        }

        

        $('.archive-search-submit').on('click', function(e){
            e.preventDefault();
            var year = $('.select-year').val();
            var month = $('.select-month').val();
            if(month === null) {
                month = '01';
            }
            if(year === null) {
                year = '2020';
            }
            window.location = "/" + year + '/' + month;
        });


        var menuHtml = '<div class="mobile-menu-extras"><div class="search-holder"><div id="et_mobile_search"><span id="et_search_icon"></span></div><div class="mobile-search-container container et_search_form_container et_pb_search_visible et_pb_no_animation"><form role="search" method="get" class="et-search-form" action="/" style="max-width: 192px;"><input type="search" class="et-search-field" placeholder="Search …" value="" name="s" title="Search for:" style="font-size: 16px;"></form></div></div>';
        menuHtml += '<ul class="et-social-icons mobile">';
        menuHtml += '<li class="et-social-icon et-social-facebook"><a href="https://www.facebook.com/RegionalArtsCommission" class="icon"><span>Facebook</span></a></li>';
        menuHtml += '<li class="et-social-icon et-social-twitter"><a href="https://twitter.com/RACStLouis" class="icon"><span>Twitter</span></a></li>';
        menuHtml += '<li class="et-social-icon et-social-instagram"><a href="http://instagram.com/RegionalArtsCommission" class="icon"><span>Instagram</span></a></li>';
        menuHtml += '<li class="et-social-icon et-social-youtube"><a href="https://www.youtube.com/channel/UCnQDsBuNmSQhLx99MNEDmng" class="icon"><span>YouTube</span></a></li>';
        menuHtml += '<li class="et-social-icon et-social-vimeo"><a href="https://vimeo.com/user2744781" class="icon"><span>Vimeo</span></a></li>';
        menuHtml += '</ul></div>';
        setTimeout(function(){ 
            var secondaryItemFirst = $( ".et_mobile_menu .menu-item-206442" ).clone();
            var secondaryItemSecond = $( ".et_mobile_menu .menu-item-207499" ).clone();
            $( ".et_mobile_menu .menu-item-206442" ).remove();
            $( ".et_mobile_menu .menu-item-207499" ).remove();
            $('#mobile_menu').append(menuHtml);
            secondaryItemFirst.insertAfter('.search-holder');
            secondaryItemSecond.insertAfter('.search-holder');
            $('.mobile-search-container').hide();

            $('#et_mobile_search').on('click', function(e) {
                $('.mobile-search-container').fadeIn();
            });

            $('.et_mobile_menu .menu-item-has-children a').append('<div class="open-sub-menu"></div>')
            $('.et_mobile_menu .open-sub-menu').on('click', function(e) {
                e.stopPropagation();
                e.preventDefault();
                $(this).parents('.menu-item-has-children').find('.sub-menu').slideToggle().toggleClass('open');
            });
         }, 1000);
        

        if($('body').hasClass('post-type-archive-public-art')) {
            // public art listing js
            initMap();

            $('#filter-public-art').bind('keypress keydown keyup', function(e){
                if(e.keyCode == 13) { e.preventDefault(); }
            });

            $('#next_public_art').on('click', function(e) {
                e.preventDefault();
                // get more public art + offset
                var offset = parseInt( $('#public-art-offset').val() );
                //increment offset
                $('#public-art-offset').val(offset + 12);
                $('#prev_public_art').removeClass('hidden');
                getFilteredArt();
                
            });
            $(document).on('click', '.pagination-container a.page-numbers', function(e) {
                e.preventDefault();
                if( $(this).hasClass('last') ) {
                    var last = true;
                } else {
                    var last = false;
                }
                var pageToLoad = $(this).attr('data-num');
                if(!pageToLoad) {
                    var pageToLoad = $(this).text();
                    if(pageToLoad == 'Next') {
                        pageToLoad = 2;
                    }
                }
                console.log(pageToLoad);
                $('#current-page').val(pageToLoad);
                getFilteredArt(pageToLoad, last);
            });
            $('#prev_public_art').on('click', function(e) {
                e.preventDefault();
                // get more public art + offset
                var offset = parseInt( $('#public-art-offset').val());
                //decrement offset
                if((offset - 12) > 0) {
                    $('#public-art-offset').val(offset - 12);
                } else {
                    $('#public-art-offset').val(0);
                    $('#prev_public_art').addClass('hidden');
                }
                getFilteredArt();
            });

            $('#public-art-search-filter').bind('input keyup', function(e){
                //reset paging offset
                $('#public-art-offset').val(0);
                $('#prev_public_art').addClass('hidden');
                if(e.keyCode == 13) {
                    e.preventDefault();
                }
                var $this = $(this);
                var delay = 1000; // 2 seconds delay after last input
            
                clearTimeout($this.data('timer'));
                $this.data('timer', setTimeout(function(){
                    $this.removeData('timer');
                        getFilteredArt();
                }, delay));
            });

            $('#clear-all-filters').on('click', function(e) {
                //reset paging offset
                $('#public-art-offset').val(0);
                $('#filter-public-art select').find('option:selected').each(function() {
                    $(this).prop("selected", false);
                });
                $('.multi-select-menu').find('input').each(function() {
                    $(this).prop('checked', false); 
                });
                $('.current-filters .items').html('');
                $('#clear-all-filters').hide();
                getFilteredArt();
            });

            var choices = [];
            $('#filter-public-art select').on('change', function(e) {
                //reset paging offset
                $('#public-art-offset').val(0);
                $('#prev_public_art').addClass('hidden');
                var html = '';
                $('#filter-public-art select').find('option:selected').each(function() {
                    var title = $(this).text();
                    var type = $(this).attr('data-type');
                    var name = $(this).val();
                    var tax = '';
                    if (type === 'public-art-category') {
                        tax = "Category";
                    } else if( type === 'public-art-tag') {
                        tax = "Tag";
                    } else if( type === 'region') {
                        tax = "Region";
                    }
                    var choice = [tax, title, name, type];
                    choices.push(choice);
                    html += '<div data-type="' + choice[3] + '" data-val="' + choice[2] + '" class="selected-indicator">' + choice[0] + ': ' + choice[1] + '<span class="close">x</span></div>';
                });
                
                if(choices !== undefined || choices.length > 0 ){
                    $('#clear-all-filters').css('display', 'inline-block');
                    $('.current-filters .items').html(html);
                } else {
                    $('#clear-all-filters').hide();
                }

                getFilteredArt();
            });

            $('body').on('click', '.selected-indicator .close', function(e) {
                var div = $(this).parents('.selected-indicator');
                var id = div.attr('data-val');
                var type = div.attr('data-type');
                var spanText = div.text();
                // uncheck the corresponding select boxes
                $('#filter-public-art select').find('option:selected').each(function() {
                    if($(this).val() === id) {
                        $(this).prop("selected", false);
                    }
                });
                $('#filter-public-art select').next('.multi-select-container').find('.multi-select-menuitems input').each(function() {
                    if($(this).val() === id) {
                        $(this).prop("checked", false);
                    }
                });
                // remove the selected-indicator div
                div.remove();
                //reset dropdown labels
                if( $('#cat-public-art-category option:selected').length > 0 ) {
                    // remove the closed filter label from the list of selected options
                    var label = $('#cat-public-art-category').next('.multi-select-container').find('.multi-select-button').text();
                    var category = spanText.replace('Category: ', '').slice(0,-1);
                    label = label.replace(', ' + category,'');
                    label = label.replace(category,'');
                    $('#cat-public-art-category').next('.multi-select-container').find('.multi-select-button').text(label);
                } else {
                    $('#cat-public-art-category').next('.multi-select-container').find('.multi-select-button').text('Category');
                }
                if( $('#region-select option:selected').length > 0 ) {
                    // remove the closed filter label from the list of selected options
                    var label = $('#region-select').next('.multi-select-container').find('.multi-select-button').text();
                    var category = spanText.replace('Region: ', '').slice(0,-1);
                    label = label.replace(', ' + category,'');
                    label = label.replace(category, '');
                    $('#region-select').next('.multi-select-container').find('.multi-select-button').text(label);
                } else {
                    $('#region-select').next('.multi-select-container').find('.multi-select-button').text('Regions');
                }
                // if there are no more indicators, hide the clear all button
                if ( ! $( '.selected-indicator' ).length ) {
                    $('#clear-all-filters').hide();
                }
                // fetch the new data
                getFilteredArt();
            });
    
            $('.view-options h5').on('click', function(e) {
                $('.view-options h5').removeClass('selected');
                $(this).addClass('selected');
                var view = $(this).attr('id');
                if(view === 'g-map') {
                    $('.map-container').fadeIn();
                    $('#public-art-container').hide();
                    $('.pagination-container').hide();
                } else if (view ==='list') {
                    $('.map-container').hide();
                    $('.pagination-container').show();
                    $('#public-art-container').css('display', 'block');
                    $('#public-art-container').removeClass();
                    $('#public-art-container').addClass('list');
                } else if (view === 'grid') {
                    $('.map-container').hide();
                    $('.pagination-container').show();
                    $('#public-art-container').css('display', 'grid');
                    $('#public-art-container').removeClass();
                    $('#public-art-container').addClass('grid');
                }
                    //$('#public-art-container').removeClass();
                    //$('#public-art-container').addClass(view);
            });
        } 
        

        $('#region-select').multiSelect({noneText: 'Regions'});
        $('#cat-public-art-category').multiSelect({noneText: 'Category'});
        $('#cat-public-art-tag').multiSelect({noneText: 'Tag'});
        $('#cat_type').multiSelect({noneText: 'Type'});
        $('#cat_discipline').multiSelect({noneText: 'Discipline'});

        // preselect options on cat member directory
        var selectedTypes = 'Type';
        var i = 0;
        $('#cat_type option').each(function(e) {
            if( $(this).attr("selected") == 'selected' ) {
                if(i === 0) {
                    selectedTypes = '';
                }
                i++;
                var val = $(this).val();
                var text = $(this).text();
                if(i > 1) {
                    selectedTypes += ', '
                }
                selectedTypes += text;
                $('.multi-select-menuitems input').each(function(e) {
                    if($(this).val() == val) {
                        $(this).prop('checked', true);
                    }
                })
            }
        });
        $('#cat_type').next('.multi-select-container').find('.multi-select-button').text(selectedTypes);

        var selectedDisciplines = 'Discipline';
        var i = 0;
        $('#cat_discipline option').each(function(e) {
            if( $(this).attr("selected") == 'selected' ) {
                if(i === 0) {
                    selectedDisciplines = '';
                }
                i++;
                var val = $(this).val();
                var text = $(this).text();
                if(i > 1) {
                    selectedDisciplines += ', '
                }
                selectedDisciplines += text;
                $('.multi-select-menuitems input').each(function(e) {
                    if($(this).val() == val) {
                        $(this).prop('checked', true);
                    }
                })
            }
        });
        $('#cat_discipline').next('.multi-select-container').find('.multi-select-button').text(selectedDisciplines);

        $('#cat_type option').each(function(e) {
            if($(this).attr('selected')) {
                $(this).prop('selected', true);
            }
        })
        $('#cat_discipline option').each(function(e) {
            if($(this).attr('selected')) {
                $(this).prop('selected', true);
            }
        })
        
    });
    

})(jQuery);